import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { Container, Grid, Box, Typography, useMediaQuery } from "@mui/material"
import Masonry from "@mui/lab/Masonry"
import { useTheme } from "@mui/material/styles"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"

import { PNScrollAnimation } from "../PNScrollAnimation"
import { Segment, Section } from "../Layout"
import { SegmentTitle } from "../SegmentTitle"
import { SectionTitle } from "../SectionTitle"

import { usePageData } from "../../hooks/usePageData"

// import { useAboutPageQuery } from "../../hooks/useAboutPageQuery"

const About = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "md"))
  // const {
  //   wpPage: {
  //     acfAbout: { ceoBio, fieldGroupName, header, orgCreed },
  //   },
  // } = useAboutPageQuery()

  const aboutusimage = getImage(
    usePageData().wpPage.homepage.aboutImage.localFile
  )

  const photos = [
    {
      src: usePageData().wpPage.homepage.networkingImage1.localFile.publicURL,
      width: 5,
      height: 3,
    },
    {
      src: usePageData().wpPage.homepage.networkingImage2.localFile.publicURL,
      width: 5,
      height: 3,
    },
    {
      src: usePageData().wpPage.homepage.networkingImage3.localFile.publicURL,
      width: 5,
      height: 3,
    },
  ]

  return (
    <>
      <Segment>
        <Container disableGutters>
          <PNScrollAnimation animateIn="fadeIn">
            <Box>
              <SegmentTitle>ABOUT</SegmentTitle>
            </Box>
          </PNScrollAnimation>
          <Section>
            <PNScrollAnimation animateIn="fadeIn">
              <Grid container>
                <Grid item xs={12} md={5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pr: isSmall ? 0 : 4,
                    }}
                    mb={isSmall ? 4 : 0}
                  >
                    <GatsbyImage
                      image={aboutusimage}
                      alt="male entrepreneur talking"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box>
                    <SectionTitle align={isSmall ? "center" : "initial"}>
                      {usePageData().wpPage.homepage.aboutTitle}
                    </SectionTitle>
                    <SectionTitle
                      align={isSmall ? "center" : "initial"}
                      subtitle
                      gutterBottom
                    >
                      {usePageData().wpPage.homepage.aboutSubTitle}
                    </SectionTitle>

                    <Typography
                      align={isSmall ? "center" : "initial"}
                      paragraph
                    >
                      {usePageData().wpPage.homepage.aboutContent}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </PNScrollAnimation>
          </Section>
        </Container>
      </Segment>
      <PNScrollAnimation animateIn="fadeInUp">
        <Box sx={{ with: "100%" }}>
          <Masonry columns={3}>
            {photos.map(({ src }, index) => (
              <Box
                component="img"
                src={src}
                alt={`image ${index}`}
                key={index}
                loading="lazy"
              />
            ))}
          </Masonry>
        </Box>
      </PNScrollAnimation>
    </>
  )
}

About.propTypes = {
  children: PropTypes.node,
}

export default About
