import { styled } from "@mui/system"
import { rem } from "polished"
import { Box } from "@mui/material"

const StyledChapters = styled(Box)(({ theme }) => ({
  padding: `${rem(64)}`,
  [theme.breakpoints.down("xs")]: {
    padding: `${rem(16)}`,
  },
}))

const StyledChapterBox = styled(Box)(({ theme, bgImg }) => ({
  backgroundImage: `url(${bgImg})`,
  backgroundSize: "cover",
  position: "relative",
  minHeight: `${rem(240)}`,
  width: "100%",
  [theme.breakpoints.down("xs")]: {
    padding: `${rem(16)}`,
  },
}))

const StyledChapterBoxCopyContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  minHeight: `240px`,
  position: "relative",
  zIndex: 1005,
  padding: `${rem(16)}`,
})

const StyledOverlay = styled(Box)(({ theme, bgImg }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1000,
  width: "100%",
  minHeight: `${rem(240)}`,
  backgroundColor: "rgba(255,255,255,0.85)",
  overflowY: "auto",
}))

export {
  StyledChapters,
  StyledChapterBoxCopyContainer,
  StyledChapterBox,
  StyledOverlay,
}
