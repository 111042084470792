import React from "react"
import cuid from "cuid"
import PropTypes from "prop-types"
import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { StaticImage } from "gatsby-plugin-image"

import { PNScrollAnimation } from "../PNScrollAnimation"
import { Segment, Section, Center } from "../Layout"
import { SectionTitle } from "../SectionTitle"
import { SegmentTitle } from "../SegmentTitle"
import { ThemeButton } from "../ThemeButton"

import IconWine from "../../images/events/wine.svg"
import IconTeacup from "../../images/events/teacup.svg"
import IconVideoConference from "../../images/events/video-conference.svg"

import {usePageData} from "../../hooks/usePageData"
const parse = require('html-react-parser');

function getIcon(iconname){
  if(iconname == "wine"){
    return IconWine;
  } else if(iconname == "teacup"){
    return IconTeacup;
  } else {
    return IconVideoConference;
  }
}

const Events = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "md"))

  return (
    <Segment variant="secondary">
      <Container disableGutters>
        <PNScrollAnimation animateIn="fadeIn">
          <Box>
            <SegmentTitle color="common.white">Events</SegmentTitle>
          </Box>
        </PNScrollAnimation>
        <Section>
          <PNScrollAnimation animateIn="fadeIn">
            <Grid container>
              <Grid item xs={12}>
                <Box mb={4}>
                  <SectionTitle align="center" color="common.white">
                    {usePageData().wpPage.homepage.eventTitle}
                  </SectionTitle>
                  <SectionTitle align="center" subtitle gutterBottom>
                    {usePageData().wpPage.homepage.eventSubTitle}
                  </SectionTitle>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {usePageData().wpPage.homepage.events.map(({ buttonText, caption, email, icon, title, website }) => (
                <Grid item xs={12} md={4}>
                  <Center>
                    <Box p={2}>
                      <Center>
                        <Box
                          component="img"
                          src={getIcon(icon)}
                          sx={{ height: "100px", width: "100px" }}
                          mb={2}
                        />
                      </Center>
                      <Typography variant="h6" align="center" color="primary">
                        <strong>{title}</strong>
                      </Typography>
                      <Box mb={2} sx={{ minHeight: `100px` }}>
                        <Typography align="center" color="common.white">
                          {parse(caption)}
                        </Typography>
                      </Box>
                      <Center>
                        <Button
                          href={
                            email
                              ? `mailto:${email}?subject=RSVP ${title}`
                              : website
                          }
                          variant="contained"
                          color="primary"
                          target="_blank"
                          rel="noopener"
                        >
                          {buttonText}
                        </Button>
                      </Center>
                    </Box>
                  </Center>
                </Grid>
              ))}
            </Grid>
          </PNScrollAnimation>
        </Section>
      </Container>
    </Segment>
  )
}

Events.propTypes = {
  children: PropTypes.node,
}

export default Events
