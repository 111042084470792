import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  MutableRefObject,
} from "react"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { fetchChapters } from "../../store/reducers/chapters.reducer"
import { RootState, AppDispatch } from "../../store/store"

import { About } from "../About"
import { CEO } from "../CEO"
import { Advantages } from "../Advantages"
import { Chapters } from "../Chapters"
import { ContactHero } from "../Contact"
import { Home } from "../Home"
import { Join } from "../Join"
import { Events } from "../Events"
import { JoinCta } from "../JoinCta"
import { Layout } from "../Layout"
import { Philosophy } from "../Philosophy"

const ScrollContainer: React.FC = () => {
  const refHome = useRef<HTMLDivElement>(null)
  const refAbout = useRef<HTMLDivElement>(null)
  const refCeo = useRef<HTMLDivElement>(null)
  const refAdvantages = useRef<HTMLDivElement>(null)
  const refChapters = useRef<HTMLDivElement>(null)
  const refJoin = useRef<HTMLDivElement>(null)
  const refEvents = useRef<HTMLDivElement>(null)
  const refPhilosophy = useRef<HTMLDivElement>(null)
  const refContact = useRef<HTMLDivElement>(null)

  const [posHome, setPosHome] = useState<number | null>(null)
  const [posAbout, setPosAbout] = useState<number | null>(null)
  const [posCeo, setPosCeo] = useState<number | null>(null)
  const [posAdvantages, setPosAdvantages] = useState<number | null>(null)
  const [posChapters, setPosChapters] = useState<number | null>(null)
  const [posJoin, setPosJoin] = useState<number | null>(null)
  const [posEvents, setPosEvents] = useState<number | null>(null)
  const [posPhilosophy, setPosPhilosophy] = useState<number | null>(null)
  const [posContact, setPosContact] = useState<number | null>(null)

  const dispatch = useDispatch<AppDispatch>()
  const chaptersData = useSelector(
    (state: RootState) => state.chapters.chapters
  )
  const chaptersStatus = useSelector(
    (state: RootState) => state.chapters.status
  )
  const error = useSelector((state: RootState) => state.chapters.error)

  useLayoutEffect(() => {
    function updatePosition() {
      if (refHome.current) setPosHome(refHome.current.offsetTop)
      if (refAbout.current) setPosAbout(refAbout.current.offsetTop)
      if (refCeo.current) setPosCeo(refCeo.current.offsetTop)
      if (refAdvantages.current)
        setPosAdvantages(refAdvantages.current.offsetTop)
      if (refChapters.current) setPosChapters(refChapters.current.offsetTop)
      if (refJoin.current) setPosJoin(refJoin.current.offsetTop)
      if (refEvents.current) setPosEvents(refEvents.current.offsetTop)
      if (refPhilosophy.current)
        setPosPhilosophy(refPhilosophy.current.offsetTop)
      if (refContact.current) setPosContact(refContact.current.offsetTop)
    }
    window.addEventListener("resize", updatePosition)
    updatePosition()
    return () => window.removeEventListener("resize", updatePosition)
  }, [])

  useEffect(() => {
    if (chaptersStatus === "idle") {
      dispatch(fetchChapters())
    }
  }, [chaptersStatus, dispatch])

  return (
    <>
      <section id="home" ref={refHome}></section>
      <Layout
        yAxisHome={posHome}
        yAxisAbout={posAbout}
        yAxisCeo={posCeo}
        yAxisAdvantages={posAdvantages}
        yAxisChapters={posChapters}
        yAxisJoin={posJoin}
        yAxisEvents={posEvents}
        yAxisPhilosophy={posPhilosophy}
        yAxisContact={posContact}
      >
        <section>
          <Home />
        </section>
        <Box sx={{ position: "relative" }} ref={refAbout}>
          <Box id="about" sx={{ position: "absolute", top: "-135px" }}></Box>
          <About />
        </Box>
        <Box sx={{ position: "relative" }} ref={refCeo}>
          <Box id="ceo" sx={{ position: "absolute", top: "-135px" }}></Box>
          <CEO />
        </Box>
        <Box sx={{ position: "relative" }} ref={refAdvantages}>
          <Box
            id="advantages"
            sx={{ position: "absolute", top: "-135px" }}
          ></Box>
          <Advantages />
          <JoinCta />
        </Box>

        <Box sx={{ position: "relative" }} ref={refChapters}>
          <Box id="chapters" sx={{ position: "absolute", top: "-135px" }}></Box>
          <Chapters chapters={chaptersData} />
        </Box>
        <Box sx={{ position: "relative" }} ref={refJoin}>
          <Box id="join" sx={{ position: "absolute", top: "-135px" }}></Box>
          <Join />
        </Box>
        <Box sx={{ position: "relative" }} ref={refEvents}>
          <Box id="events" sx={{ position: "absolute", top: "-135px" }}></Box>
          <Events />
        </Box>

        <Box sx={{ position: "relative" }} ref={refPhilosophy}>
          <Box
            id="philosophy"
            sx={{ position: "absolute", top: "-135px" }}
          ></Box>
          <Philosophy />
        </Box>

        <Box sx={{ position: "relative" }} ref={refContact}>
          <Box id="contact"></Box>
          <ContactHero />
        </Box>
      </Layout>
    </>
  )
}

export default ScrollContainer
