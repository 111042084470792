import React, { ReactNode } from "react"
import { Container, Box, Typography, Grid } from "@mui/material"

import { PNScrollAnimation } from "../PNScrollAnimation"
import { Segment, Section } from "../Layout"
import { SegmentTitle } from "../SegmentTitle"
import ChaptersSwiper from "./ChaptersSwiper"

import { usePageData } from "../../hooks/usePageData"

interface ChaptersProps {
  children?: ReactNode
}
const Chapters: React.FC<ChaptersProps> = ({ children }) => {
  const pageData = usePageData()

  return (
    <Segment>
      <Container disableGutters>
        <PNScrollAnimation animateIn="fadeIn">
          <Box>
            <SegmentTitle>Chapters</SegmentTitle>
          </Box>
        </PNScrollAnimation>

        <Section>
          <PNScrollAnimation animateIn="fadeIn">
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="secondary" align="center">
                  {pageData.wpPage.homepage.chaptersTitle}
                </Typography>
                <Typography
                  variant="h5"
                  color="primary"
                  align="center"
                  gutterBottom
                >
                  {pageData.wpPage.homepage.chaptersSubTitle}
                </Typography>
                <Typography paragraph align="center">
                  {pageData.wpPage.homepage.chaptersContent}
                </Typography>
              </Grid>
            </Grid>
          </PNScrollAnimation>
        </Section>
        <Box>
          <ChaptersSwiper />
        </Box>
      </Container>
    </Segment>
  )
}

export default Chapters
