import React from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material"
import { navigate } from "gatsby"
import { states, membershipOptions } from "./Join.api"
import { AgreementTerms } from "./AgreementTerms"
import { FormValues } from "./Join.types"

export const JoinForm: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      membership: "new-member",
    },
  })

  const encode = (data: Record<string, any>) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = async (data: FormValues) => {
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "join-us-06-10-2023",
          ...data,
        }),
      })
      navigate("/thank-you/")
    } catch (error) {
      alert(error)
    }
  }

  return (
    <form
      data-netlify="true"
      name="join-us-06-10-2023"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
    >
      <input type="hidden" name="form-name" value="join" />
      <Grid container>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      variant="filled"
                      fullWidth
                      color="secondary"
                      error={!!errors.firstName}
                      helperText={
                        errors.firstName ? "First Name is required" : ""
                      }
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      variant="filled"
                      fullWidth
                      color="secondary"
                      error={!!errors.lastName}
                      helperText={
                        errors.lastName ? "Last Name is required" : ""
                      }
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={5}>
                <Controller
                  name="streetAddress"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Street Address"
                      variant="filled"
                      fullWidth
                      color="secondary"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="city"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="City"
                      variant="filled"
                      fullWidth
                      color="secondary"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="state"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="state-label">State</InputLabel>
                      <Select
                        {...field}
                        labelId="state-label"
                        id="state"
                        label="State"
                        color="secondary"
                      >
                        <MenuItem disabled value="">
                          <em>Select State</em>
                        </MenuItem>
                        {states.map(({ name, abbreviation }) => (
                          <MenuItem value={abbreviation} key={abbreviation}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="zip"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Zip Code"
                      variant="filled"
                      fullWidth
                      color="secondary"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="businessName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Business Name"
                      variant="filled"
                      fullWidth
                      color="secondary"
                      error={!!errors.businessName}
                      helperText={
                        errors.businessName ? "Business Name is required" : ""
                      }
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="businessEmail"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Business Email"
                      variant="filled"
                      type="email"
                      fullWidth
                      color="secondary"
                      error={!!errors.businessEmail}
                      helperText={
                        errors.businessEmail ? "Business Email is required" : ""
                      }
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="businessType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Business Type"
                      variant="filled"
                      fullWidth
                      color="secondary"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Phone"
                      variant="filled"
                      type="tel"
                      fullWidth
                      color="secondary"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="website"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Website"
                      variant="filled"
                      type="text"
                      fullWidth
                      color="secondary"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="birthday"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Birthday"
                      variant="filled"
                      fullWidth
                      color="secondary"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ py: 2 }} columns={{ xs: 3, md: 12 }}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Membership Type</FormLabel>
                  <Controller
                    name="membership"
                    control={control}
                    defaultValue="new-member"
                    render={({ field }) => (
                      <RadioGroup
                        row
                        aria-label="membership"
                        {...field}
                        value={field.value}
                      >
                        {membershipOptions.map(({ label, value }) => (
                          <FormControlLabel
                            value={value}
                            control={<Radio color="secondary" />}
                            label={label}
                            key={value}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Controller
                  name="preferredChapter"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Preferred Chapter"
                      variant="filled"
                      fullWidth
                      color="secondary"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Controller
                  name="conflictDateTime"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Conflict Days/Times"
                      variant="filled"
                      fullWidth
                      color="secondary"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Controller
                  name="goalsNeeds"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Goals & Needs"
                      variant="filled"
                      multiline
                      rows={4}
                      fullWidth
                      color="secondary"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box sx={{ my: 4 }}>
              <AgreementTerms />
            </Box>
            <Grid container mb={4}>
              <Grid item xs={12}>
                <Box>
                  <FormGroup>
                    <Controller
                      name="agreement"
                      control={control}
                      defaultValue={false}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                color="secondary"
                                checked={field.value}
                              />
                            }
                            label="By checking the box I certify that I am agreeing to all terms and conditions stated above."
                          />
                          {errors.agreement && (
                            <FormHelperText error>
                              You must agree to the terms and conditions
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                size="large"
                color="secondary"
                sx={{ color: "white" }}
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
      </Grid>
    </form>
  )
}
