import React from "react"
import moment from "moment"
import { rem } from "polished"
import { Box, Container, Link, Typography } from "@mui/material"
import { convertToBgImage } from "gbimage-bridge"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"

import { PNScrollAnimation } from "../PNScrollAnimation"
import { Section } from "../Layout"
import { SegmentTitle } from "../SegmentTitle"
import { SectionTitle } from "../SectionTitle"
import { usePageData } from "../../hooks/usePageData"
import { JoinForm } from "./JoinForm"

const Join: React.FC = () => {
  const bgDimensions = { height: 656, width: 12 }

  const { imgAboutHero } = useStaticQuery(
    graphql`
      query {
        imgAboutHero: file(relativePath: { eq: "images/img-join2-hero.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const image = getImage(imgAboutHero)
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
      <Box
        sx={{
          minHeight: bgDimensions.height,
          minWidth: bgDimensions.width,
          py: `${rem(64)}`,
        }}
      >
        <Container disableGutters>
          <Box>
            <PNScrollAnimation animateIn="fadeIn">
              <SegmentTitle>Join</SegmentTitle>
            </PNScrollAnimation>
          </Box>
          <Section>
            <PNScrollAnimation animateIn="fadeIn">
              <Box mb={4}>
                <SectionTitle align="center">
                  {usePageData().wpPage.homepage.joinTitle}
                </SectionTitle>
                <SectionTitle align="center" subtitle>
                  {usePageData().wpPage.homepage.joinSubTitle}
                </SectionTitle>
                <Typography align="center">
                  Please complete this fast form and you’ll be on your way to
                  dynamic growth. If you have questions, just{" "}
                  <Link
                    color="secondary"
                    href="mailto:colleen@preferred-networking.com"
                  >
                    contact our CEO, Colleen
                  </Link>
                  .
                </Typography>
              </Box>
            </PNScrollAnimation>
            <PNScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
              <JoinForm />
            </PNScrollAnimation>
          </Section>
        </Container>
      </Box>
    </BackgroundImage>
  )
}

export default Join
