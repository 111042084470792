import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import {
  Container,
  Grid,
  Box,
  Typography,
  Link,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"

import { PNScrollAnimation } from "../PNScrollAnimation"
import { Segment, Section } from "../Layout"
import { SectionTitle } from "../SectionTitle"

import {usePageData} from "../../hooks/usePageData"

const CEO = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "md")) // const {
  //   wpPage: {
  //     acfColleen: { ceoBio, fieldGroupName, header, orgCreed },
  //   },
  // } = useColleenPageQuery()

  const ceoimage = getImage(usePageData().wpPage.homepage.ceoImage.localFile)

  return (
    <Segment>
      <Container disableGutters>
        <Section>
          <PNScrollAnimation animateIn="fadeIn">
            <Grid container>
              <Grid item xs={12} md={7}>
                <Box>
                  <SectionTitle align={isSmall ? "center" : "initial"}>
                  {usePageData().wpPage.homepage.ceoTitle}
                  </SectionTitle>
                  <SectionTitle
                    align={isSmall ? "center" : "initial"}
                    subtitle
                    gutterBottom
                  >
                    {usePageData().wpPage.homepage.ceoSubTitle}
                  </SectionTitle>

                  <Typography align={isSmall ? "center" : "initial"} paragraph>
                    {usePageData().wpPage.homepage.ceoContent} {`  `}
                    <strong>
                      Intrigued?{`  `}
                      <Link
                        href="mailto:Colleen@preferred-networking.com"
                        color="secondary"
                      >
                        Contact Colleen
                      </Link>{" "}
                      by email today.
                    </strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  sx={{ display: "flex", justifyContent: "center" }}
                  mt={isSmall ? 4 : 0}
                >
                  <GatsbyImage
                    image={ceoimage}
                    alt="colleen valadez entrepreneur talking"
                  />
                </Box>
              </Grid>
            </Grid>
          </PNScrollAnimation>
        </Section>
      </Container>
    </Segment>
  )
}

CEO.propTypes = {
  children: PropTypes.node,
}

export default CEO
