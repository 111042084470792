import React from "react"
import PropTypes from "prop-types"
import { Button } from "@mui/material"

import { StyledThemeScrollButton } from "./ThemeButton.styled"

const ButtonMap = {
  scroll: ({ children, ...otherProps }) => (
    <StyledThemeScrollButton {...otherProps}>
      {children}
    </StyledThemeScrollButton>
  ),
  standard: ({ children, ...otherProps }) => (
    <Button {...otherProps} sx={{ color: `rgba(255, 255, 255, 0.87)` }}>
      {children}
    </Button>
  ),
}

const ThemeButton = ({ buttonType, children, ...otherProps }) => {
  return React.createElement(ButtonMap[buttonType], { ...otherProps }, children)
}
ThemeButton.defaultProps = {
  buttonType: "standard",
  variant: "contained",
}

ThemeButton.propTypes = {
  children: PropTypes.node,
}

export default ThemeButton
