import React from "react"
import { rem } from "polished"
import { Grid, Typography, Container, Box, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import { PNScrollAnimation } from "../PNScrollAnimation"
import { SocialLinks } from "../SocialLinks"
import WebLogoWhite from "../../images/web-logo-white.svg"

import {useSiteData} from "../../hooks/useSiteData"
const parse = require('html-react-parser');

const ContactHero = ({ ...props }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const bgDimensions = { height: isSmall ? 300 : 600, width: "100%" }


  const sitelogowhite = useSiteData().siteLogo2.localFile.publicURL
  const image = getImage(useSiteData().footerBackground.localFile)

  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      {...props}
    >
      <Box
        sx={{ minHeight: bgDimensions.height, minWidth: bgDimensions.width }}
      >
        <Container disableGutters>
          <Grid container>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: isSmall ? `${rem(100)}` : bgDimensions.height,
                  pt: isSmall ? `${rem(64)}` : "initial",
                }}
              >
                <PNScrollAnimation animateIn="fadeInUp" animateOut="fadeInDown">
                  <Box>
                    <Typography
                      align={isSmall ? "center" : "initial"}
                      variant={isSmall ? "h5" : "h3"}
                      color="primary"
                    >
                      CONTACT
                    </Typography>
                    <Typography
                      align={isSmall ? "center" : "initial"}
                      color="white"
                      variant="subtitle1"
                    >
                      {useSiteData().email}
                    </Typography>
                    <Typography
                      align={isSmall ? "center" : "initial"}
                      color="white"
                      variant="subtitle1"
                    >
                      {useSiteData().phone}
                    </Typography>
                    <Typography
                      align={isSmall ? "center" : "initial"}
                      color="white"
                      variant="subtitle1"
                    >
                      {parse(useSiteData().address)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={isSmall ? "center" : "flex-start"}
                  >
                    <SocialLinks white />
                  </Box>
                </PNScrollAnimation>
              </Box>
            </Grid>
            {!isSmall && (
              <Grid item xs={12} md={7}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: isSmall ? `${rem(100)}` : bgDimensions.height,
                  }}
                >
                  <PNScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInDown"
                  >
                    <Box
                      component="img"
                      src={sitelogowhite}
                      alt="web logo white"
                      sx={{ width: isSmall ? rem(150) : rem(400) }}
                    />
                  </PNScrollAnimation>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </BackgroundImage>
  )
}
export default ContactHero
