import React from "react"
import PropTypes from "prop-types"
import { Container, Grid, Box, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"

import { PNScrollAnimation } from "../PNScrollAnimation"
import { Segment, Section, Center } from "../Layout"
import { SectionTitle } from "../SectionTitle"
import { SegmentTitle } from "../SegmentTitle"
import { ThemeButton } from "../ThemeButton"

import {usePageData} from "../../hooks/usePageData"

const parse = require('html-react-parser');

const Advantages = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "md"))
  const advantageimage = getImage(usePageData().wpPage.homepage.advantagesImages.localFile)
  return (
    <Segment variant="alternate">
      <Container disableGutters>
        <PNScrollAnimation animateIn="fadeIn">
          <Box>
            <SegmentTitle>Advantages</SegmentTitle>
          </Box>
        </PNScrollAnimation>
        <Section>
          <PNScrollAnimation animateIn="fadeIn">
            <Grid container>
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    pr: isSmall ? 0 : 4,
                  }}
                  mb={isSmall ? 4 : 0}
                >
                  <GatsbyImage
                    image={advantageimage}
                    alt="nature"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box>
                  <SectionTitle align={isSmall ? "center" : "initial"}>
                    {usePageData().wpPage.homepage.advantagesTitle}
                  </SectionTitle>
                  <SectionTitle
                    align={isSmall ? "center" : "initial"}
                    subtitle
                    gutterBottom
                  >
                    {usePageData().wpPage.homepage.advantagesSubTitle}
                  </SectionTitle>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      {parse(usePageData().wpPage.homepage.advantagesListLeft)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {parse(usePageData().wpPage.homepage.advantagesListRight)}
                    </Grid>
                  </Grid>

                  {/* <Box>
                    <Center>
                      <ThemeButton>Non eum atque</ThemeButton>
                    </Center>
                  </Box> */}
                </Box>
              </Grid>
            </Grid>
          </PNScrollAnimation>
        </Section>
      </Container>
    </Segment>
  )
}

Advantages.propTypes = {
  children: PropTypes.node,
}

export default Advantages
