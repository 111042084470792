import React from "react"
import {
  Grid,
  Typography,
  Container,
  Box,
  Button,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Scrollchor, easeOutQuad } from "react-scrollchor"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import { PNScrollAnimation } from "../PNScrollAnimation"
import { ThemeButton } from "../ThemeButton"

import {usePageData} from "../../hooks/usePageData"


const GbiBridged = ({ ...props }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isSmallandMediumPortrait = useMediaQuery(
    theme.breakpoints.between("xs", "lg")
  )
  const bgDimensions = { height: 967, width: "100%" }

  const image = getImage(usePageData().wpPage.homepage.heroBackgroundImage.localFile)

  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      {...props}
    >
      <Box
        sx={{ minHeight: bgDimensions.height, minWidth: bgDimensions.width }}
      >
        <Container disableGutters>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: bgDimensions.height,
                }}
              >
                <Box sx={{ px: isSmallandMediumPortrait ? 2 : 0 }}>
                  <PNScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInDown"
                  >
                    <Box>
                      <Typography
                        variant="h2"
                        color="secondary"
                        align={isSmall ? "center" : "initial"}
                      >
                        {usePageData().wpPage.homepage.heroTitle}
                      </Typography>

                      <Typography
                        variant="h6"
                        align={isSmall ? "center" : "initial"}
                      >
                        {usePageData().wpPage.homepage.heroSubTitle}
                      </Typography>
                    </Box>

                    <Box
                      mt={4}
                      display={isSmall ? "flex" : "inherit"}
                      justifyContent={isSmall ? "center" : "inherit"}
                    >
                      <ThemeButton to="#about" buttonType="scroll">
                        Learn More
                      </ThemeButton>
                    </Box>
                  </PNScrollAnimation>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Container>
      </Box>
    </BackgroundImage>
  )
}
export default GbiBridged
