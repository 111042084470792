import React from "react"
import { rem } from "polished"
import PropTypes from "prop-types"
import { Typography, Box } from "@mui/material"
import { useTheme } from "@mui/system"

import { StyledSegmentTitle } from "./SegmentTitle.styled"

const SegmentTitle = ({ white, children, ...props }) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ display: "inline-block" }}>
        <Typography
          sx={{ fontSize: "30px" }}
          color={white ? "white" : "common.grey"}
          align="center"
          {...props}
        >
          <strong>{children}</strong>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              height: "5px",
              backgroundColor: theme.palette.primary.dark,
              width: "50%",
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

SegmentTitle.propTypes = {
  children: PropTypes.node,
}

export default SegmentTitle
