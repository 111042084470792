import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { navigate } from "gatsby"
import {
  Box,
  Typography,
  ButtonBase,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"
import { Navigation, Pagination, Scrollbar, A11y, History } from "swiper"
// import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import {
  StyledChapterBox,
  StyledChapterBoxCopyContainer,
  StyledOverlay,
} from "./Chapters.styled"

import {
  fetchChapters,
  updateCurrentScreen,
} from "../../store/reducers/chapters.reducer"
import { chapterImages } from "./Chapters.content"

interface ChaptersSwiperProps {}

const ChaptersSwiper: React.FC<ChaptersSwiperProps> = () => {
  const dispatch = useDispatch()
  const chapters = useSelector((state: any) => state.chapters.chapters)
  const currentScreen = useSelector(
    (state: any) => state.chapters.currentScreen
  )
  const chaptersStatus = useSelector((state: any) => state.chapters.status)
  const swiper = useSwiper()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))

  const handleClick = (route: string, updateScreen: number) => {
    dispatch(updateCurrentScreen(updateScreen))
    navigate(route)
  }

  const convertToSlug = (Text: string) => {
    console.log("text", Text)
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "")
  }

  return (
    <div>
      {chapters.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ ml: 2 }}>
            Loading chapters...
          </Typography>
        </Box>
      ) : (
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, History]}
          spaceBetween={32}
          slidesPerView={isSmall ? 1 : 3}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={swiperInstance => {
            swiperInstance.slideTo(currentScreen)
          }}
        >
          {chapters.map((chapter, idx) => (
            <SwiperSlide key={chapter.id}>
              <ButtonBase
                onClick={() =>
                  handleClick(
                    `/chapters/${convertToSlug(chapter.chapterName)}`,
                    idx
                  )
                }
                sx={{ width: "100%" }}
                initialSlide={4}
              >
                <StyledChapterBox bgImg={chapterImages[idx]}>
                  <Box sx={{ position: "relative" }}>
                    <StyledChapterBoxCopyContainer>
                      <Typography variant="h5" align="center">
                        <strong>{chapter.chapterName}</strong>
                      </Typography>
                      <Typography align="center">
                        {chapter.meetingDay}
                      </Typography>
                      <Typography align="center">
                        {chapter.meetingTime}
                      </Typography>
                    </StyledChapterBoxCopyContainer>
                    <StyledOverlay></StyledOverlay>
                  </Box>
                </StyledChapterBox>
              </ButtonBase>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}

export default ChaptersSwiper
