import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import { usePageData } from "../../hooks/usePageData.js"

export const AgreementTerms: React.FC = () => {
  const footerContent = usePageData().wpPage.homepage.joinFooterContent
  return (
    <Box
      sx={{ padding: 2 }} // optional styling, you can customize it as needed
      dangerouslySetInnerHTML={{ __html: footerContent }} // safely inject the HTML content
    />
  )
}
