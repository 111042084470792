import React, { useState } from "react"
import { Grid, Typography, Container, Box } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { AnimationOnScroll } from "react-animation-on-scroll"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const GbiBridged = ({ ...props }) => {
  const bgDimensions = { height: 492, width: 1280 }
  const { imgAboutHero } = useStaticQuery(
    graphql`
      query {
        imgAboutHero: file(relativePath: { eq: "images/img-about-hero.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(imgAboutHero)

  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      {...props}
    >
      <Box
        sx={{ minHeight: bgDimensions.height, minWidth: bgDimensions.width }}
      >
        <Container disableGutters>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: bgDimensions.height,
                }}
              >
                <AnimationOnScroll animateIn="fadeInUp" animateOut="fadeInDown">
                  <Box>
                    <Typography variant="h3" color="primary">
                      INCIDIDUNT UT LABORE ET DOLORE
                    </Typography>
                    <Typography color="white">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud
                      exercitation.
                    </Typography>
                  </Box>
                </AnimationOnScroll>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Container>
      </Box>
    </BackgroundImage>
  )
}
export default GbiBridged
